import { handleErrorWithSentry, Replay } from "@sentry/sveltekit";
import * as Sentry from "@sentry/sveltekit";
import { building } from "$app/environment";
import { env } from "$env/dynamic/public";
const { PUBLIC_ENV } = env;

if (!building)
  Sentry.init({
    dsn: "https://3f904725cd1f40bfa7a99c1678a0e09e@sentry.tippingservice.es/5",
    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.5,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    // If you don't want to use Session Replay, just remove the line below:
    integrations: [new Replay()],
    environment: `client-${PUBLIC_ENV}`
  });

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
