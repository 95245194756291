import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/avisos-legales": [3],
		"/celebraciones": [~4],
		"/celebraciones/[slug]": [~5],
		"/contacto": [6],
		"/eventos-corporativos": [~7],
		"/eventos-corporativos/[slug]": [~8],
		"/gastronomia": [~9],
		"/gastronomia/[slug]": [~10],
		"/mi-evento": [11],
		"/mi-evento/legacyUI": [~13],
		"/mi-evento/[shortId]": [12],
		"/nuestros-espacios": [~14],
		"/nuestros-espacios/[slug]": [~15],
		"/politica-de-privacidad": [16],
		"/quienes-somos": [17],
		"/testimonios": [18]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';